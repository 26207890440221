// Side Bar
.sidebar-s {
  z-index: 10;
  height: 100%;
  position: fixed;
  // left: 0px;
  top: 0;
  bottom: 0;
  padding: 12px 0px 12px 12px;

  .side-block {
    width: 280px;
    background-color: $themeColor;
    height: 100%;
    overflow-y: auto;
    transition: all 0.3s cubic-bezier(0.9, 0.32, 0.46, 1.18) 0s;

    &::-webkit-scrollbar {
      width: 5px;
    }

    @include media(">=phone", "<=480px") {
      width: 70%;
    }

    .hdr {
      padding: 16px 10px;
      width: 100%;

      // background-color: $themeColor;
      // border-bottom: 1.4px solid #ffffff;
      .hdr-tag {
        font-weight: 700;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
      }

      .icon-close {
        height: 20px;
        cursor: pointer;

        svg {
          height: 30px;
          width: 30px;

          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }

    .items {
      padding: 20px 16px;

      .items-list {
        svg {
          height: 18px;
          width: 18px;

          path {
            // fill: #fff;
          }
        }

        .li {
          width: 100%;
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          align-items: start;
          @include anim;

          @include media("<=tablet") {}
        }

        &.active {
          background: #ffffff;

          svg {
            path {
              stroke: $themeColor;
            }
          }

          .li {
            color: $themeColor;
          }
        }
      }
    }

    .sidebar-footer {
      padding: 12px 20px 24px 20px;
      width: 100%;

      .action {
        .btn {
          padding: 8px 18px;
          background: #f1f1f1;
          border-radius: 30px;

          .btn-lbl {
            margin-left: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
        }
      }
    }
  }
}