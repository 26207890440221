.lading-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;

  .wrap {
    .home-grid {
      .grid-box {
        box-shadow: 0px 4px 10px 0px #00000026;

        .icon {
          svg {
            height: 60px;
            width: 60px;

            path {
              stroke: #47A000;
            }

          }
        }

        &:nth-child(2) {
          .icon {
            svg {
              path {
                stroke: #0066A0;
              }
            }
          }
        }

        &:nth-child(3) {
          .icon {
            svg {
              path {
                stroke: #A0009A;
              }
            }
          }
        }
      }
    }
  }
}