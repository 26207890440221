@font-face {
  font-family: 'MRe';
  src: local('Manrope-Regular'), url('./fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MMe';
  src: local('Manrope-Medium'), url('./fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MBo';
  src: local('Manrope-Bold'), url('./fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MSBo';
  src: local('Manrope-SemiBold'), url('./fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IBo';
  src: local('Inter-Bold'), url('./fonts/Inter-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IMe';
  src: local('Inter-Medium'), url('./fonts/Inter-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IRe';
  src: local('Inter-Regular'), url('./fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'PRe';
  src: local('Poppins-Regular'), url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PMe';
  src: local('Poppins-Medium'), url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PSBo';
  src: local('Poppins-SemiBold'), url('./fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PLi';
  src: local('Poppins-Light'), url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}