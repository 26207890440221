.finance-detail-page {
    .wrap {
        .left-side {
            .client-info {
                border-top: 4px solid $themeColor;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
        }

        .right-side {
            .invoice-detail {}
        }
    }
}