$pageWidth: 280px;
$pageWidth65: 0;
@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "../components/Header/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../Pages/Home/style.scss";
@import "../Pages/FinanceDetail/style.scss";

.page-width {
  width: $pageWidth;
}

.page-width65 {
  width: $pageWidth65;
}

.font,
body {
  font-family: "Inter", sans-serif;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 100%;
  margin: 0 auto;
  padding: 0px 3%;

  @include media("<=xlarge") {
    width: 100%;
    margin: 0 auto;
    padding: 0px 3%;
  }

  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }

  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
}

//Drop Down
.dropDown {
  width: 100%;

  .category {
    width: 100%;

    .cbox {
      // background: #000403;
      border: 1px solid #bababa;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;

      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }

      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }

      .slt {
        overflow: hidden;

        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }

        .lbl {
          color: #000;
          padding: 0 10px;
        }

        .unit-name {
          .unit-eng {
            color: #000000;
          }
        }
      }

      .d-img {
        margin: 2px 10px;

        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }

      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }

      .dropDown-icon {
        cursor: pointer;
      }
    }
  }

  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #000403;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;

    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;

        &.active {
          background-color: $themeColor;
          color: #242424;

          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }

        .unit-name {
          .unit-eng {
            color: #eee;
          }
        }

        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }

        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }

        &:hover {
          background-color: #1e4231;
          cursor: pointer;

          .ico {
            color: $themeColor;
          }

          .lbl {
            color: $themeColor;
          }
        }
      }
    }

    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Wrapper
.dashboard-page {
  height: 100%;
  min-height: 100vh;
  margin-left: $pageWidth;
  background: #ffffff;
  @include anim;

  @include media("<=tablet") {
    margin-left: 0;
  }

  &.full-open {
    margin-left: $pageWidth;

    @include media("<=tablet") {
      margin-left: 0;
    }
  }
}

.responsive-table {
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px #00000014;

  &::-webkit-scrollbar {
    height: 5px;
  }
}

.dropdown {
  position: relative;
}

.dropdown ul {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 0;
  list-style: none;
  margin: 0;
}

.dropdown ul li {
  padding: 5px 10px;
}

.dropdown ul li:hover {
  background-color: #f5f5f5;
}

// .hidden {
//   display: none;
// }

.img-box {
  svg {
    width: 100px;
    height: 40px;

    // path {
    //   fill: #d6d6d6;
    // }
  }
}

.manueProfile {
  background: #fff;
  width: 110px;
  position: absolute;
  top: 45px;
  right: 0px;
  padding: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px;
  border-radius: 4px;

  &.show {
    @include dropDownAnim;
  }
}


.menuList-block {
  top: 0px;
  left: -50px;
  right: 0;
  border-radius: 4px;
  border: 1px solid $themeColor;
  background: $themeColor;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  width: 100%;


  .menu-item {
    width: 100%;
    overflow-y: auto;
    padding: 0px 0px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    .slt {
      width: 100%;
      padding: 8px 16px;
      @include anim;
      border-bottom: 1px solid #ffffff;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &.active {
        background-color: $themeColor;
        color: #242424;
      }
    }
  }

  &.show {
    display: flex;
    visibility: visible;
    opacity: 1;
    @include dropDownAnim;
  }
}

.add-ad-model {
  .img-box {
    svg {
      height: 18px;
    }
  }
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}